<template>
  <div>
    <b-container fluid class="p-2">
      <b-row class="m-2">
        <b-col cols="12">
          <b-button-toolbar key-nav aria-label="Fatura İşlemler">
            <b-button to="/Cari/0" variant="success" class="mx-1">Yeni Oluştur</b-button>
            <b-button v-b-toggle.filtreleriAc variant="primary">Filtrele</b-button>
            <b-button-group class="mx-1" v-if="totalRows > 0" right>
              <download-excel
                :data="excelExportList"
                :footer="excelFooter"
                :header="excelHeader"
                stringifyLongNum:true
                :fields="excelSutunlar"
                escapeCsv:false
                name="Cari Listesi.xls"
              >
                <b-button>Excel</b-button>
              </download-excel>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
        <b-col cols="12">
          <b-collapse id="filtreleriAc">
            <b-card title="Filtreler">
              <b-row>
                <b-col cols="12" lg="4" xl="4">
                  Cari Ad
                  <b-form-input
                    v-model="filtreler.unvan"
                    placeholder="Ad/Ünvan"
                  ></b-form-input>
                </b-col>
                <b-col cols="12" lg="4" xl="4">
                  Kod
                  <b-form-input v-model="filtreler.kod" placeholder="Kod"></b-form-input>
                </b-col>
                <b-col cols="12" lg="4" xl="4">
                  Tip
                  <b-form-select
                    v-model="filtreler.tip"
                    class="form-control"
                    :options="$store.state.cariTipler"
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" lg="4" xl="4">
                  TC/VKN
                  <b-form-input v-model="filtreler.vn" placeholder=""></b-form-input>
                </b-col>
                <b-col cols="12" lg="4" xl="4">
                  Kategori
                  <b-form-input
                    v-model="filtreler.kategorileri"
                    placeholder=""
                  ></b-form-input>
                </b-col>
                <b-col cols="12" lg="4" xl="4"></b-col>
              </b-row>
            </b-card>
          </b-collapse>
        </b-col>
      </b-row>
      <b-row class="m-2">
        <b-col cols="12">
          <b-card header-tag="header">
            <template #header>
              <h6 class="mb-0">Faturalar</h6>
              <h4 class="text-muted">
                Cari Yönetim<b-badge class="badge alert-info m-2"
                  >{{ totalRows }} Adet Cari</b-badge
                >
              </h4>
            </template>
            <div v-show="totalRows > 0">
              <b-row>
                <b-col cols="12" xl="6" lg="6">
                  <b-dropdown
                    id="dropdown-form"
                    text="Gizle/Göster"
                    class="m-2"
                    align="left"
                  >
                    <b-dropdown-form>
                      <b-checkbox
                        :disabled="visibleFields.length == 1 && field.visible"
                        v-for="field in kolonlar.filter((o) => o.label != '#')"
                        :key="field.key"
                        v-model="field.visible"
                        inline
                      >
                        {{ field.label }}
                      </b-checkbox>
                    </b-dropdown-form>
                  </b-dropdown>
                </b-col>

                <b-col cols="12" xl="6" lg="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="right"
                    pills
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>

            <b-table
              hover
              responsive
              :items="cariFiltred"
              :fields="visibleFields"
              :current-page="currentPage"
              :per-page="perPage"
              show-empty
              label-sort-clear
              label-sort-asc
              label-sort-desc
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              small
              sort-icon-left
              empty-filtered-text="Kayıt Bulunamadı"
              empty-text="Kayıt Bulunamadı"
            >
              <template #cell(bakiye)="data">
                <div class="paraKolonu ortaKolonUzunluk">
                  {{ $store.getters.formatPrice(data.value) }}
                  <span v-if="parseFloat(data.value) < 0">(A)</span>
                  <span v-else-if="parseFloat(data.value) > 0">(B)</span>
                  <span v-else></span>
                </div>
              </template>

              <template #cell(unvan)="data">
                {{ data.value }}

                <p v-if="data.item.kategoriler">
                  <b-badge
                    variant="info"
                    class="badge alert-info m-1"
                    v-for="(kat, index) in data.item.kategoriler.split(',')"
                    v-bind:key="kat[index]"
                  >
                    {{ kat }}
                  </b-badge>
                </p>
              </template>

              <template #cell(bilgi)="data">
                <span class="row" v-if="data.item.mailler">
                  {{ data.item.mailler.split(",")[0] }}
                </span>

                <span class="row" v-if="data.item.telefonlar">
                  {{ data.item.telefonlar.split(",")[0] }}
                </span>

                <span class="row" v-if="data.item.webler">{{
                  data.item.webler.split(",")[0]
                }}</span>
              </template>
              <template #cell(tip)="data">
                <span v-if="data.value == null || data.value == ''">Alıcı</span>
                <span
                  v-else-if="!isNaN(parseFloat(data.value)) && isFinite(data.value)"
                  >{{
                    $store.state.cariTipler.filter(
                      (o) => o.value === parseInt(data.value)
                    )[0].text
                  }}</span
                >
                <span v-else>Alıcı</span>
              </template>

              <template #cell(kayitTarihi)="data">
                {{ data.value | moment("DD.MM.YYYY hh:ss") }}
              </template>
              <template #cell(eFatmi)="data">
                {{ data.value ? "E-Fatura" : "E-Arşiv" }}
              </template>
              <template #cell(actions)="data">
                <b-dropdown right>
                  <b-dropdown-item :to="'/Cari/' + data.item.Id">Düzenle</b-dropdown-item>
                  <b-dropdown-item>Hareketleri</b-dropdown-item>
                  <b-dropdown-item>K.K.Tahsilat Link Gönder</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>

                  <b-dropdown-item>Nakit Tahsilat</b-dropdown-item>
                  <b-dropdown-item>Çek Tahsilat</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item>Nakit Ödeme</b-dropdown-item>
                  <b-dropdown-item>Çek Ödeme</b-dropdown-item>

                  <b-dropdown-divider></b-dropdown-divider>

                  <b-dropdown-item>Sil</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { isNumber } from "util";
import { all } from "q";
import moment from "moment";
import VueMoment from "vue-moment";
require("moment/locale/tr");
moment.locale("tr");
export default {
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      totalRows: 0,
      excelExportList: [],
      excelSutunlar: {
        Kod: "kod",
        "Ad/Ünvan": "ad",
        Tip: "tip",
        Bakiye: "bakiye",
      },
      excelFooter: "Monoryum E-Dönüşüm Sistemi",
      excelHeader: "",
      sortBy: "kod",
      sortByModal: "ad",
      ss: "d-none",
      sortDesc: true,
      filtreler: {
        unvan: undefined,
        kod: undefined,
        tip: undefined,
        vn: undefined,
        kategorileri: undefined,
      },

      kolonlar: [
        {
          key: "kod",
          sortable: true,
          label: "Kod",
          visible: true,
        },
        { key: "kayitTarihi", sortable: true, label: "Kayıt Tarihi", visible: false },
        { key: "eFatmi", sortable: true, label: "E-Fat./E-Arşiv", visible: false },
        { key: "unvan", sortable: true, label: "Ad/Ünvan", visible: true },
        { key: "tip", sortable: true, label: "Tip", visible: true },
        { key: "bilgi", sortable: true, label: "Bilgi", visible: true },
        { key: "adres", sortable: true, label: "Adres", visible: false },
        { key: "ulke", sortable: true, label: "Ülke", visible: false },
        { key: "bakiye", sortable: true, label: "Bakiye", visible: true },
        { key: "actions", label: "#", visible: true },
      ],
    };
  },

  computed: {
    visibleFields() {
      return this.kolonlar.filter((field) => field.visible);
    },
    cariFiltred: function () {
      var filtered = this.$store.state.sirketAyarlar.tumCariler;

      if (this.filtreler.kod) {
        const regexp = new RegExp(this.filtreler.kod, "i");
        filtered = filtered.filter((lesson) => lesson.kod.match(regexp));
      }
      if (this.filtreler.unvan) {
        const regexp = new RegExp(this.filtreler.unvan, "i");
        filtered = filtered.filter((lesson) => lesson.unvan.match(regexp));
      }
      console.log(this.filtreler.tip);
      if (this.filtreler.tip) {
        filtered = filtered.filter(
          (lesson) => lesson.tip === this.filtreler.tip.toString()
        );
      }
      if (this.filtreler.kategorileri) {
        const regexp = new RegExp(this.filtreler.kategorileri, "i");
        filtered = filtered.filter(
          (lesson) => lesson.kategoriler != null && lesson.kategoriler.match(regexp)
        );
      }

      this.totalRows = filtered.length;

      var obj = this.visibleFields
        .filter((o) => o.key != "actions")
        .reduce((acc, post) => {
          let { key, label } = post;

          return { ...acc, [label]: (label, key) };
        }, {});

      this.excelSutunlar = obj;
      this.excelExportList = [];

      for (let dt in filtered) {
        this.excelExportList.push({
          kod: filtered[dt].kod,
          unvan: filtered[dt].unvan,
          tip:  !isNaN(parseFloat(filtered[dt].tip))  ? this.$store.state.cariTipler.filter((o) => o.value == filtered[dt].tip)[0]
            .text:'Alıcı',
          kayitTarihi: moment(filtered[dt].kayitTarihi).format("DD.MM.YYYY"),
          eFatmi: filtered[dt].eFatmi ? "E-Fatura" : "E-Arşiv",
          adres: filtered[dt].adres,
          ulke: filtered[dt].ulke,
          bakiye: this.$store.getters.formatPrice(filtered[dt].bakiye),
          bilgi:
            (filtered[dt].telefonlar ? filtered[dt].telefonlar : "") +
            " " +
            (filtered[dt].mailler ? filtered[dt].mailler : "") +
            " " +
            (filtered[dt].webler ? filtered[dt].webler : ""),
        });
      }

      return filtered;
    },
  },
  created() {
    this.$store.dispatch("sirketCariler", { cariId: 0,gizliAl:false });
  },
};
</script>

<style></style>
